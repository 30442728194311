<template>
  <div>
    <!-- 导航 -->
    <div
      style="
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        right: 0px;
        z-index: 10;
      "
    >
      <van-nav-bar
        title="确认订单"
        fixed
        left-text=""
        left-arrow
        @click-left="$goBack()"
        @touchmove.prevent
      >
      </van-nav-bar>
    </div>
    <!-- 导航 -->
    <div style="margin: 50px 0; padding: 0 0 50px">
      <!-- 收货地址 -->
      <div @click="$router.push('/shippingAddress')">
        <div
          v-if="address == ''"
          style="
            display: flex;
            padding: 15px 15px;
            align-items: center;
            background-color: #ffffff;
            flex-direction: column;
          "
        >
          <div>
            <van-icon size="20px" name="add-o" />
          </div>
          <div>点击添加收货地址</div>
        </div>
        <div v-if="address != ''">
          <van-cell is-link center>
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span class="title">{{ address.userName }}</span>
              <span class="title" style="margin-left: 6px">{{
                address.telNum
              }}</span>
              <van-tag
                color="#FF0038"
                text-color="#ffffff"
                v-show="address.isDefault == 1"
                style="margin-left: 8px"
                >默认</van-tag
              >

              <!-- <van-tag color="#3E85FF" text-color="#ffffff">家</van-tag> -->
            </template>
            <!-- 使用 label 插槽来自定义描述 -->
            <template #label>
              <span class="label">{{ address.detailInfo }}</span>
            </template>
          </van-cell>
        </div>
      </div>
      <!-- 收货地址 -->
      <!-- <div style=" background-color: #f1f1f1; height:15px;"></div> -->
      <!-- 商品价格和详情 -->
      <!-- <div v-if="form">
    <div  style="padding: 0 15px;background-color: #ffffff;" v-for="(item,index) in form" :key="index">
      <div v-if="index==0" style="padding-top:15px;">{{item.shopName}}</div>
      <div style="display: flex;justify-content: space-between;padding: 15px 0;">
        <div style="display: flex;">
          <div>
            <van-image style="width:80px;height:80px;margin-right:10px;" fit="cover" :src="item.skus.picUrl" />
          </div>
          <div style="display: flex; flex-direction:column;justify-content: space-between;">
            <div>{{item.name?item.name:''}}</div>
            <div style="color:#b2b2b2">
              <span>规格：</span>
              <span v-for="(specs,index) in item.skus.specs" :key="specs.specValueId+index">
                {{specs.specValueName+' '}}
              </span>
            </div>
          </div>
        </div>
        <div style="display: flex; flex-direction:column;align-items: flex-end;justify-content: space-between;">
          <div style="color: #A80000;font-weight: 500;font-size: 18px;">
            <span style="font-size: 14px;">￥</span>
            <span style="font-size: 18px;">{{item.skus.salesPrice}}</span>
          </div>
          <div>*{{item.num}}</div>
        </div>
      </div>
    </div> -->
      <!-- </div> -->
      <div style="padding: 0 15px 15px; background-color: #ffffff">
        <div style="margin-bottom: 15px">
          <span style="margin-right: 20px">配送方式</span>
          <span>普通快递</span>
        </div>
        <div class="card">
          <img src="" alt="" />
        </div>
        <van-card
          centered
          v-if="goodData.activeprice"
          num="1"
          :price="goodData.activeprice"
          :title="goodData.name"
          :thumb="goodData.img"
        >
          <template #desc>
            <span style="opacity: 0"
              >1 <br />
              1
            </span>
          </template>
        </van-card>

        <van-card
          v-else
          num="1"
          :price="goodData.price"
          :title="goodData.name"
          :thumb="goodData.img"
        >
          <template #desc>
            <span style="opacity: 0">
              1<br />
              1
            </span>
          </template>
        </van-card>
        <div>
          <div style="margin-right: 20px">商品备注</div>
          <van-field
            v-model="remark"
            rows="1"
            autosize
            type="textarea"
            placeholder="选填 请先和商家协商一致"
            style="padding: 10px 0"
          />
        </div>
        <div style="margin-top: 15px">
          <div style="text-align: right" v-if="goodData.seckillPrice">
            <span>共{{ goodData.quantity }}件商品</span>
            <span>小计：</span>
            <span style="color: #a80000; font-size: 14px; font-weight: 500"
              >￥</span
            >
            <span
              style="color: #a80000; font-size: 18px; font-weight: 500"
              v-if="goodData.type != 0"
              >{{ goodData.activeprice }}</span
            >
            <span
              style="color: #a80000; font-size: 18px; font-weight: 500"
              v-else
              >{{ goodData.price }}</span
            >
          </div>
        </div>
      </div>
      <!-- 商品价格和详情 -->
      <div style="background-color: #f1f1f1; height: 15px"></div>
      <van-cell
        title="商品总额"
        v-if="goodData.type == 0"
        :value="'￥' + goodData.price"
        value-class="priceStyle"
      ></van-cell>
      <van-cell
        title="商品总额"
        v-else
        :value="'￥' + goodData.activeprice || goodData.price"
        value-class="priceStyle"
      ></van-cell>

      <!-- <van-cell title="积分抵扣"  value="￥48.28" value-class="priceStyle"></van-cell> -->
      <!-- <van-cell title="优惠卷" is-link @click="$router.push('/coupon')" center>
      <template #default>
          <div><van-button color="#ff7000" size="mini">2张可用</van-button></div>
        </template>
      </van-cell> -->

      <!-- <div class="">
        <van-cell-group class="mony">
          <van-switch-cell  icon="balance-o" size="20" v-model="checked" title="钱包余额" />
        </van-cell-group>
      </div> -->
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell clickable @click="radio = '1'">
            <template #title>
              <img class="img" src="../../assets/img/weChat-icon.png" alt />
              <span class="custom-title">微信</span>
            </template>
            <template #right-icon>
              <van-radio name="1" />
            </template>
          </van-cell>
          <van-cell clickable @click="radio = '2'">
            <template #title>
              <img class="img" src="../../assets/img/alipay-icon.png" alt />
              <span class="custom-title">支付宝</span>
            </template>
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>

    <!-- 底部 -->
    <div class="bots" style="position: fixed; bottom: 0px" v-if="goodData">
      <van-submit-bar
        v-if="goodData.type !== '0'"
        :price="(goodData.activeprice || goodData.price) * 100"
        button-text="提交订单"
        @submit="onSubmit"
      />
      <van-submit-bar
        v-else
        :price="(goodData.activeprice || goodData.price) * 100"
        button-text="提交订单"
        @submit="onSubmit"
      />
    </div>
    <!-- 底部 -->
  </div>
</template>
<script>
import { aliPay } from "../../api/AliPay/index";
import { getPage } from "@/api/shoppingAddress/shoppingAddress";
import { submitOrder, wxPay } from "@/api/sureOrder/sureOrder";
import { Toast } from "vant";

let backAddress = "";
export default {
  data() {
    return {
      form: [],
      remark: "",
      address: "",
      radio: "1",
      chosenCoupon: -1,
      coupons: [],
      disabledCoupons: [],
      checked: true,
      totalPrice: 0,
      totalNum: 0,
      goodData: "",
      data: "",
      id: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "ShippingAddress") {
      if (from.params.address) backAddress = from.params.address;
    } else {
      backAddress = "";
    }
    next();
  },
  created() {
    if (this.$route.query.info != null) {
      if (typeof this.$route.query.info == "object")
        this.form = this.$route.query.info;
      else this.form = [this.$route.query.info];
      for (let index = 0; index < this.form.length; index++) {
        const element = this.form[index];
        this.form[index] = JSON.parse(element);
        this.totalPrice +=
          Number(this.form[index].skus.salesPrice) *
          Number(this.form[index].num);
        this.totalNum += Number(this.form[index].num);
      }
    }
    if (backAddress == "" || !backAddress) {
      if (
        this.$route.query.address != null &&
        this.$route.query.address != ""
      ) {
        if (JSON.parse(this.$route.query.address) != "") {
          this.address = JSON.parse(this.$route.query.address);
        } else {
          this.getPage();
        }
      } else {
        this.getPage();
      }
    } else {
      this.address = backAddress;
    }
    this.goodData = JSON.parse(this.$route.query.data);
  },
  mounted() {
    window.closePage = this.closePage;
  },
  methods: {
    getPage() {
      getPage({ current: 1, size: 10 }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.records.length == 0) {
            this.address = "";
          } else {
            this.address = res.data.data.records[0];
          }
        }
      });
    },
    onSubmit() {
      if (this.address == "") {
        Toast("请选择地址");
        return;
      }
      this.goodData.price;
      // let skus = [];
      // for (let info of this.form) {
      //   let element = {
      //     spuId: info.skus.spuId,
      //     skuId: info.skus.id,
      //     quantity: info.num,
      //     paymentPrice: String(Number(info.skus.salesPrice) * Number(info.num)),
      //     freightPrice: "0",
      //   };
      //   skus = skus.concat(element);
      // }

      let appType = "";
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        appType = "ios";
      }
      if (isAndroid) {
        appType = "Android";
      }
      let marketId = sessionStorage.getItem("marketId");

      let relationId = JSON.parse(this.$route.query.data);
      let form = {
        paymentWay: this.goodData.paymentWay,
        deliveryWay: this.goodData.deliveryWay,
        paymentType: this.radio,
        userMessage: this.remark,
        appType: appType,
        userAddressId: this.address.id,
        orderType: this.goodData.type,
        tradeType: "APP",
        marketId: marketId || this.goodData.markedId,
        relationId: relationId.relationId,
        type: "4", //this.goodData.type==0?'4':null
        // userId: this.$store.state.userDate.id,
        skus: [
          {
            spuId: this.goodData.spuId,
            skuId: this.goodData.skuId,
            paymentPrice:
              this.goodData.type == 0
                ? this.goodData.price
                : this.goodData.activeprice || this.goodData.price,

            quantity: this.goodData.quantity,
            freightPrice: "0",
            //  paymentPoints:'',
            //  paymentCouponPrice:'',
            //  paymentPointsPrice:'',
            //  couponUserId:''
          },
        ],
      };
      submitOrder(form).then((res) => {
        if (res.data.code === 0) {
          // let alipayForm = {
          //   outTradeNo: res.data.data[0].orderNo,
          //   pamentPrice: this.goodData.price||this.goodData.activeprice,
          // };
          this.id = res.data.data[0].id;
          if (this.radio === "2") {
            this.submitAlipay(res.data.data[0].id)
          } else {
            this.getWxPay([res.data.data[0].id]);
          }
        } else {
          Toast(res.data.msg);
        }
      });
    },

    closePage() {
      this.$router.back();

      // if(data==1) return
      // Toast('下单成功')
      // location.href = document.referrer;
      // window.history.go(-1);
      //  this.$router.replace({
      //       path:'/mallOrderDetail',
      //       query:{
      //         id:this.id
      //       }
      //     })
    },
    getWxPay(data) {
      wxPay({ id: data, tradeType: "APP" }).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data == null) {
            this.$toast.success("下单成功");
            this.$goBack();
            return;
          }

          let u = navigator.userAgent;
          let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
          let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

          if (isIOS) {
            window.webkit.messageHandlers.wechatPay.postMessage(
              JSON.stringify(res.data.data)
            );
          }
          if (isAndroid) {
            window.android.wxpay(
              res.data.data.appId,
              res.data.data.partnerId,
              res.data.data.prepayId,
              res.data.data.nonceStr,
              res.data.data.timeStamp,
              res.data.data.packageValue,
              res.data.data.sign
            );
          }
        } else {
          Toast(res.data.msg);
        }
      });
    },
    submitAlipay(id) {
      aliPay({ id: id, tradeType: "APP" }).then((res) => {
        if (res.data.data == null) {
          this.$toast.success("下单成功");
          this.$goBack();
          return;
        }
        if (res.status === 200) {
          this.data = res;
          let u = navigator.userAgent;
          let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
          let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
          if (isIOS) {
            window.webkit.messageHandlers.alipay.postMessage(res.data.data);
          }

          if (isAndroid) {
            window.android.alipay(res.data.data);
          }
        }
      });
    },
    // isPay(data){
    //   if(data){
    //     //
    //   }else{
    //     //
    //   }
    // }
  },
  // payCB(data){
  //   if(data){
  //     this.$router.replace({
  //           path:'/orders',
  //           query:{
  //             id:this.id
  //           }
  //         })

  //   }else{
  //     this.$router.replace({
  //           path:'/orders',
  //           query:{
  //             id:this.id
  //           }
  //         })

  //   }

  // }
};
</script>
<style lang="less" scoped>
.van-nav-bar .van-icon {
  color: black;
}
.bots {
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  display: flex;
  background-color: #ffffff;
  justify-content: space-evenly;
  z-index: 10;
}
.img {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  vertical-align: middle;
}

.priceStyle {
  font-size: 14px;
  font-weight: 500;
  color: #a80000;
}
/deep/ .van-ellipsis {
  z-index: 100;
  // padding: .066667rem 0 !important;
}
</style>
